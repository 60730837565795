import React from 'react';

import { Layout, SEO } from 'components';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <h1>404 - wskazana strona nie istnieje</h1>
  </Layout>
);

export default NotFoundPage;
